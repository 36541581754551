.headernav:hover{
  background-color: #555555 !important;
}
.gradient{
    background: linear-gradient(90deg, #45BAF5 -0.56%, #0057B8 68.91%);
    /* background: linear-gradient(to right,#45BAF5 10% ,#0057B8 100%); */
   
    -webkit-text-fill-color: transparent;
    background-clip: text;
}
.gradientMission{
  background: radial-gradient(44.13% 73.19% at 120.38% 8.59%, rgba(0, 78, 166, 0.66) 0%, #151515 100%);
}
/* .gradientTeam{
  background: radial-gradient(66.88% 110.91% at -38.61% 90.05%, rgba(0, 78, 166, 0.66) 0%, #151515 100%)
} */
.back{
    background: linear-gradient(to right,#0057B8 0%,#45BAF5 100% );
}
.border{
    border-radius: 18px;
  /* padding: 1rem; */
  /* position: relative; */
  background: #212529;
  /* background: linear-gradient(to right,#45BAF5, #0057B8); */
  padding: 10px;

}

.right_side_gradient{
 
    background-image: radial-gradient( circle farthest-side at 300% 10% ,#0057B8 ,black);
}
.left_side_gradient{
 
    background-image: radial-gradient( farthest-side at -300% 55%,#0057B8 ,#151515);
   
    /* background-position:; */
}
/* .heroBannerContainer {
  background-image: url(assets/Hero-Banner_2.jpg);
  width: 100%;
  height: 100vh;
} */
.heroBanner{
  position: relative;
  margin-bottom: -4px;
}
.HeroBannerContent {
  width: 100%;
  /* max-width:980px; */
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}
.vennDiagram{
  position: relative;
  margin-bottom: -4px;
  z-index: 2;
}
.vennDiagram:hover {
  cursor: pointer;
}
.vennDiagramContent {
  z-index: 0;
  /* top: 50%; */
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}
.voice_power {
  background-image: url(assets/Blackbackground.png);
  background-size: 100% 100%;
}
.vp_gradient {
  background: linear-gradient(90deg, #45BAF5 -0.56%, #0057B8 68.91%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.gv_gradient {
  background: linear-gradient(90deg, #9E0945 -0.56%, #F8723E 68.91%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.gv_img {
  width: 100%;
  width: -webkit-fill-available;
  max-width: -webkit-fill-available;
  /* width: -moz-available; */
  /* width: fill-available; */
}
.cm1_text{
  background: linear-gradient(180deg, #004EA6 0%, #1C3C76 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 700;
  font-size: 32px;
}
.cm2_text{
  background: linear-gradient(180deg, #DE5824 0%, #B00A4D 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 700;
  font-size: 32px;
}
.cm3_text{
  background: linear-gradient(180deg, #004EA6 0%, #005933 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 700;
  font-size: 32px;
}
.cm4_text{
  background: linear-gradient(180deg, #B8235F 0%, #0057B8 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 700;
  font-size: 32px;
}

.Ulist {
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  padding-left: 15px;
  /* font-size: "16px";
  font-weight: 400; */
}

.Ulist .list::before {
    content: "\2022";
    color:  #0057B8;
    font-weight: bold;
    display: inline-block; 
    width: 1em;
    margin-left: -1em;
  }
  .content_hover{
    
      display:none;
     
      
  }
  .content:hover .content_hover{
      display:block;
      text-align: center;
      transition: 0.8s ;
      transition-timing-function: ease-in-out;
      
      /* transition-duration: 5s; */
      /* transition-timing-function:cubic-bezier(8,1,3,5); */
     /* transform: translate3d(0, 0, 0); */
  }
  .normal {
    width: 150px;
    padding: 20px;
  }
  .content:hover .normal{
    
    /* transform: scale(0.8);
    transition: shakeanimation 1s;
    transition: all 1s ease-in-out; */
      /* background-image: url("./assets/87-7Million_Hoverstage.svg"); */
      display:none;
      /* transition: all 1s ease-in-out; */
      
     
      
  }
  /* .content:hover{
      cursor: pointer;
      background-color:#0057B8 ;
  } */
  .vp_1:hover{
    background: linear-gradient(180deg, #004EA6 0%, #1C3C76 100%);
  }
  .vp_2:hover{
    background: linear-gradient(180deg, #DE5824 0%, #B00A4D 100%);
  }
  .vp_3:hover{
    background: linear-gradient(180deg, #004EA6 0%, #005933 100%);
  }
  .vp_4:hover{
    background: linear-gradient(180deg, #B8235F 0%, #0057B8 100%);
  }
  .content_video:hover{
    cursor: pointer;
  }
  .learnMore{
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    border-radius: 5px;
  }
  .learnMore:hover{
    background-color: rgb(218, 213, 213) !important;
  }
  .g_AI{
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    border-radius: 5px;
  }
  .LoomA:hover{
    background-color:#B8235F !important; 
  }
  .CanvaA:hover{
    background-color:#58C1F6 !important;
  }
  .DeepgramA:hover{
    background-color:#F8723E !important;
  }
  .MsA:hover{
    background-color:#1A734D !important;
  }
  
  .content:hover .border-voice{
    border-radius: 5px;
    padding: 1rem;
    position: absolute;
    display:block;
    background: linear-gradient(to right,#45BAF5, #0057B8);
    padding: 3px;
  }
  /* .content:hover .content_hover img::before{
    animation: shakeanimation;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
  } */
 
  @keyframes shakeanimation {
    0% { transform: translateY(1px);
          opacity:0 }
  25% { transform: translateY(2px) }
  50% { transform: translateY(-2px) }
  75% { transform: translateY(1px) }
  100% { transform: translateY(0);
        opacity:1 } 
  }

  /* @keyframes voice_power {
    0% {}
    100%{padding:20px}
  } */

.content:hover {
  box-shadow: 0px 0px 49.4055px #151515;
  border-radius: 8.98282px;
  /* animation: voice_power;
  animation-duration: 0.5s;
	animation-fill-mode: forwards;
	animation-timing-function: ease-in-out; */
}

.content img{
  animation: shakeanimation;
  animation-duration: 1s;
  /* transition: all 1s ease-in-out; */
  transform-origin: 10%;
}
.sticky{
  position:sticky;
  top:0px;
  z-index: 99;
  background-color: rgb(29 29 29 / 80%);
  backdrop-filter:blur(5px);
  box-shadow: 0 20px 30px rgba(0,0,0,0.15);
  padding: 0px 22px;
  /* filter: blur(8px); */
  /* -webkit-filter: blur(8px); */
}
.Menu{
  width:200px;
  position:relative;

}
.links{
  width:200px;
  padding:12px;
  border:none;
  outline: none;

}
.List{
  position:absolute;
  transform:scaleY(0);
  transform-origin: top;
  transition:0.3s;

}
.newlist{
  transform:scaleY(1);
}

@media screen and (min-width: 0px) {
  .ulResp{
    padding-left: 15px;
  }
  
}
@media screen and (min-width: 600px) {
  .ulResp{
    padding-left: 30px;
  }
}
@media screen and (min-width: 900px) {
  
}
@media screen and (min-width: 1200px) {
  
}
@media screen and (min-width: 1536px) {
  
}
